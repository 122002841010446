import * as React from "react";
import { Suspense, lazy } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Toaster } from "@/components/ui/toaster";
import { cn } from "@/lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { Image } from "@/components/ui/image";
import { UtmSourceEnum } from "@/store/state";

// Lazy load components
const Hero = lazy(() =>
  import("./twicsy/hero").then((mod) => ({ default: mod.Hero }))
);
const HowItWorks = lazy(() =>
  import("@/components/how-it-works").then((mod) => ({
    default: mod.HowItWorks,
  }))
);
const StillCurious = lazy(() =>
  import("./twicsy/still-curious").then((mod) => ({
    default: mod.StillCurious,
  }))
);
const FamepeakPackages = lazy(() =>
  import("./twicsy/packages").then((mod) => ({ default: mod.FamepeakPackages }))
);
const ReviewSection = lazy(() =>
  import("./twicsy/review-section").then((mod) => ({
    default: mod.ReviewSection,
  }))
);
const Footer = lazy(() =>
  import("@/components/footer").then((mod) => ({ default: mod.Footer }))
);

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

interface NavigationLink {
  href: string;
  label: string;
  isDropdown?: boolean;
  items?: Array<{ href: string; label: string }>;
}

function Navigation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Add check for utm_source
  const searchParams = new URLSearchParams(location.search);
  const isGoogleAds =
    searchParams.get("utm_source")?.toLowerCase() === UtmSourceEnum.GOOGLE_ADS;

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleNavigation = (
    e: React.MouseEvent<HTMLAnchorElement>,
    href: string
  ) => {
    e.preventDefault();
    if (href.startsWith("#")) {
      const sectionId = href.slice(1);
      const section = document.getElementById(sectionId);
      if (section) {
        if (sectionId === "why-us") {
          window.scrollTo({
            top: section.offsetTop - 100,
            behavior: "smooth",
          });
        } else {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      // Preserve existing query parameters
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();
      const targetUrl = queryString ? `${href}?${queryString}` : href;
      navigate(targetUrl);
    }
    setIsOpen(false);
  };

  const baseNavigationLinks: NavigationLink[] = [
    { href: "#how-it-works", label: "How it works?" },
    { href: "#why-us", label: "Why us?" },
    { href: "#client-stories", label: "Client Stories" },
  ];

  const additionalLinks: NavigationLink[] = [
    { href: "/get-free-likes", label: "Get Free Stuff" },
    {
      href: "#",
      label: "Tools",
      isDropdown: true,
      items: [
        { href: "/tiktok-counter", label: "TikTok Followers Counter" },
        { href: "/tiktok-downloader", label: "TikTok Downloader" },
      ],
    },
  ];

  const navigationLinks = isGoogleAds
    ? baseNavigationLinks
    : [...baseNavigationLinks, ...additionalLinks];

  return (
    <div className="sticky top-0 z-50 bg-white border-b">
      <div className="container mx-auto px-4 max-w-[1005px]">
        <nav className="py-4 flex items-center">
          <div className="flex-1">
            <a href="/" className="flex items-center">
              <Image src="/famepeak.svg" alt="Famepeak" className="h-6 w-fit" />
            </a>
          </div>

          <div className="hidden md:flex items-center gap-8 text-[#4A4A4A] flex-1 justify-center whitespace-nowrap text-nowrap w-full">
            {navigationLinks.map((link) =>
              link.isDropdown ? (
                <DropdownMenu key={link.href}>
                  <DropdownMenuTrigger className="text-sm font-semibold hover:text-[#2D2D2D] !whitespace-nowrap inline-flex items-center gap-1">
                    {link.label}
                    <ChevronDown className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {link.items?.map((item) => (
                      <DropdownMenuItem key={item.href} asChild>
                        <a
                          href={item.href}
                          onClick={(e) => handleNavigation(e, item.href)}
                          className="cursor-pointer"
                        >
                          {item.label}
                        </a>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <a
                  key={link.href}
                  href={link.href}
                  className="text-sm font-semibold hover:text-[#2D2D2D] !whitespace-nowrap"
                  onClick={(e) => handleNavigation(e, link.href)}
                >
                  {link.label}
                </a>
              )
            )}
          </div>

          <div
            className="flex-1 flex justify-end items-center gap-3 relative"
            ref={menuRef}
          >
            <button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleNavigation(
                  e as unknown as React.MouseEvent<HTMLAnchorElement>,
                  "#client-stories"
                )
              }
              className="md:hidden flex items-center bg-white rounded-md pl-1 pr-2 py-1 shadow-sm border border-gray-100 hover:bg-gray-50 transition-colors"
            >
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <svg
                    key={star}
                    className="w-4 h-4 text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <span className="ml-1 text-sm font-semibold">5.0</span>
              <svg
                className="w-4 h-4 ml-1 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            <Button
              variant="ghost"
              size="icon"
              className="md:hidden bg-gray-100 hover:bg-gray-200 relative"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-[rgb(33,150,243)] rounded-full border-2 border-white"></div>
              <HamburgerMenuIcon className="h-6 w-6" />
            </Button>

            <div
              className={cn(
                "absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 transition-all duration-200 ease-in-out transform origin-top-right",
                isOpen
                  ? "opacity-100 scale-100 translate-y-0"
                  : "opacity-0 scale-95 -translate-y-2 pointer-events-none",
                "md:hidden"
              )}
            >
              {navigationLinks.map((link) =>
                link.isDropdown ? (
                  <React.Fragment key={link.href}>
                    <div className="px-4 py-2 text-sm text-gray-700 font-semibold">
                      {link.label}
                    </div>
                    {link.items?.map((item) => (
                      <a
                        key={item.href}
                        href={item.href}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 pl-8"
                        onClick={(e) => handleNavigation(e, item.href)}
                      >
                        {item.label}
                      </a>
                    ))}
                  </React.Fragment>
                ) : (
                  <a
                    key={link.href}
                    href={link.href}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-semibold"
                    onClick={(e) => handleNavigation(e, link.href)}
                  >
                    {link.label}
                  </a>
                )
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export function TwicsyLandingPage() {
  const mainColor = "rgb(33, 150, 243)";

  return (
    <div className="min-h-screen bg-background font-euclid [&_*]:text-balance">
      <Helmet>
        <title>
          Grow Your TikTok Presence with Famepeak | High-Quality Solutions
        </title>
        <meta
          name="description"
          content="Improve your TikTok presence with high-quality solutions from Famepeak. Our services provide authentic TikTok growth and engagement at an affordable price. Explore our TikTok growth solutions today."
        />
        <link
          rel="preload"
          href="/fonts/Euclid-Circular-A-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Euclid-Circular-A-Medium.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
      </Helmet>

      <Navigation />

      <Suspense
        fallback={
          <div className="h-screen flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <Hero />
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[400px] flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <HowItWorks mainColor={mainColor} />
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[400px] flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <StillCurious mainColor={mainColor} />
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[400px] flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <FamepeakPackages mainColor={mainColor} />
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[400px] flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <ReviewSection mainColor={mainColor} />
      </Suspense>

      <Suspense
        fallback={
          <div className="min-h-[200px] flex items-center justify-center">
            Loading...
          </div>
        }
      >
        <Footer />
      </Suspense>

      <Toaster />
    </div>
  );
}
