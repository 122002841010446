import React from 'react';
import { cn } from '@/lib/utils';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
  priority?: boolean;
}

export function Image({
  src,
  alt,
  width,
  height,
  className,
  priority = false,
  ...props
}: ImageProps) {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={cn('transition-opacity duration-300', className)}
      loading={priority ? 'eager' : 'lazy'}
      decoding={priority ? 'sync' : 'async'}
      {...props}
    />
  );
}

// HOC to add blur-up loading effect
export function withBlurUpload<P extends ImageProps>(
  WrappedComponent: React.ComponentType<P>
) {
  return function WithBlurUpload(props: P) {
    const [isLoaded, setIsLoaded] = React.useState(false);

    return (
      <div className="relative overflow-hidden">
        {!isLoaded && (
          <div
            className="absolute inset-0 blur-lg scale-110 transform"
            style={{
              backgroundImage: `url(${props.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        )}
        <WrappedComponent
          {...props}
          onLoad={() => setIsLoaded(true)}
          className={cn(
            props.className,
            'transition-opacity duration-300',
            isLoaded ? 'opacity-100' : 'opacity-0'
          )}
        />
      </div>
    );
  };
}

export const BlurImage = withBlurUpload(Image); 